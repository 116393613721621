import React, {useEffect, useState} from 'react';
import './App.css';
import './components/buttons/button.css'
import logo from './components/assets/moneylex_logo.png'
import {HStack, Image, Stack, Text, VStack} from "@chakra-ui/react";
import CourseBox from "./components/course_box/CourseBox";
import axios, { AxiosResponse } from 'axios';
import MyButton from "./components/buttons/MyButton";
import thaiFlag from "./components/assets/thai.png";
import russianFlag from "./components/assets/russia.png";
import usaFlag from "./components/assets/usa.png";
import earthFlag from "./components/assets/earth.png";
import Calculator, { tg } from "./components/calculator/Calculator";

interface ExchangeRates {
    rubToThbFrom: number;
    rubToThbTo: number;
    usdtToThbFrom: number;
    usdtToThbTo: number;
    usdtToRubFrom: number;
    usdtToRubTo: number;
    thbToRubFrom: number;
    thbToRubTo: number;
    usdToRubFrom: number;
    usdToRubTo: number;
    rubToUsdtFrom: number;
    rubToUsdtTo: number;
    thbToUsdtFrom: number;
    thbToUsdtTo: number;
    usdToUsdtFrom: number;
    usdToUsdtTo: number;
    rubToUsdFrom: number;
    rubToUsdTo: number;
    usdtToUsdFrom: number;
    usdtToUsdTo: number;
    flag: boolean;
}

//export const baseUrl = "https://7be1-136-169-151-109.ngrok-free.app"
export const baseUrl = "https://moneylex.online:8443"
//"https://moneylex.online:8443"

export async function checkIsUserBlocked(userId: number): Promise<boolean> {
    try {
        const response: AxiosResponse<boolean> = await axios.get(`${baseUrl}/check_user/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching exchange rates:', error);
        throw error;
    }
}

const getExchangeRates = async (): Promise<ExchangeRates> => {
    try {
        const response = await axios.get(`${baseUrl}/exchange_rates`);
        return response.data as ExchangeRates;
    } catch (error) {
        console.error('Error fetching exchange rates:', error);
        throw error;
    }
};

function Course() {
    const [course, setCourse] = useState<ExchangeRates | null>(null)
    const [isLoading, setIsLoading] = useState(true);

    const fetchExchangeRates = async () => {
        try {
            const data = await getExchangeRates();
            setCourse(data);
        } catch (error) {
            console.log('Ops, error!')
        }
    };

    useEffect(() => {
        
        setTimeout(() => setIsLoading(false), 3000);
        fetchExchangeRates();

        /*setIsLoading(false)
        fetchExchangeRates();
        setIsLoading(false)*/
    }, []);

    return (
        <div className="App">
            {/*{isLoading && tg.initData && <Loading/>}*/}
            {/*{isLoading && <Loading/>}*/}
            <VStack spacing={10} alignItems="center">
                <Image src={logo} maxW={{base: '50%', lg: '25%'}} paddingTop={{base: "25px", lg: "25px"}}/>

                <Calculator/>

                <VStack spacing={"10px"} alignItems="center" width={{base: "90vw", lg: "30vw"}} border="solid"
                        borderWidth={"1px"} borderColor={"rgba(183, 219, 0, 0.8)"} padding={"10px"}>
                    <Text fontFamily="RoadRadio" fontSize={{base: "24px", lg: "36px"}} color="white">КУРСЫ
                        ВАЛЮТ</Text>
                    {course ?
                        <>
                            <HStack>
                                <Image width={"32px"} src={thaiFlag}/>
                                <Text fontFamily="Raleway-light" fontSize={{base: "18px", lg: "20px"}}
                                      color="white">Покупка
                                    THB</Text>
                            </HStack>
                            <CourseBox courseName={"RUB/THB"} from={course.rubToThbFrom} to={course.rubToThbTo}/>
                            <CourseBox courseName={"USDT/THB"} from={course.usdtToThbFrom} to={course.usdtToThbTo}/>
                            <HStack>
                                <Image width={"32px"} src={russianFlag}/>
                                <Text fontFamily="Raleway-light" fontSize={{base: "18px", lg: "20px"}}
                                      color="white">Покупка
                                    RUB</Text>
                            </HStack>
                            <CourseBox courseName={"USDT/RUB"} from={course.usdtToRubFrom} to={course.usdtToRubTo}/>
                            <CourseBox courseName={"THB/RUB"} from={course.thbToRubFrom} to={course.thbToRubTo}/>
                            <CourseBox courseName={"USD/RUB"} from={course.usdToRubFrom} to={course.usdToRubTo}/>
                            <HStack>
                                <Image width={"32px"} src={earthFlag}/>
                                <Text fontFamily="Raleway-light" fontSize={{base: "18px", lg: "20px"}}
                                      color="white">Покупка
                                    USDT</Text>
                            </HStack>
                            <CourseBox courseName={"RUB/USDT"} from={course.rubToUsdtFrom} to={course.rubToUsdtTo}/>
                            <CourseBox courseName={"THB/USDT"} from={course.thbToUsdtFrom} to={course.thbToUsdtTo}/>
                            <CourseBox courseName={"USD/USDT"} from={course.usdToUsdtFrom} to={course.usdToUsdtTo}/>
                            <HStack>
                                <Image width={"32px"} src={usaFlag}/>
                                <Text fontFamily="Raleway-light" fontSize={{base: "18px", lg: "20px"}}
                                      color="white">Покупка
                                    USD</Text>
                            </HStack>
                            <CourseBox courseName={"RUB/USD"} from={course.rubToUsdFrom} to={course.rubToUsdTo}/>
                            <CourseBox courseName={"USDT/USD"} from={course.usdtToUsdFrom} to={course.usdtToUsdTo}/>
                        </>
                        : <Text fontFamily="RoadRadio" fontSize={{base: "24px", lg: "36px"}}
                                color="white">ЗАГРУЗКА...</Text>}
                </VStack>

                <Text fontFamily="RoadRadio" fontSize={{base: "24px", lg: "36px"}} color="white">КОНТАКТЫ</Text>

                <Stack direction={{base: "column", lg: "row"}} marginBottom={"25px"} spacing={5}>
                    <MyButton text={"Telegram"} link={"https://t.me/LEXTOP88"}/>
                    <MyButton text={"WhatsApp"} link={"https://api.whatsapp.com/send?phone=66655712578"}/>
                    <MyButton text={"Обменять валюту"} link={"https://t.me/MoneyLexBot"}/>
                    <MyButton text={"Как это работает?"} link={"https://t.me/clientreviews/49"}/>
                    <MyButton text={"Отзывы клиентов"} link={"https://t.me/clientreviews/5"}/>
                </Stack>
            </VStack>
        </div>
    );
}

export default Course;
