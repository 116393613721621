import React from "react";

interface ButtonProps {
    link: string;
    text: string;
}

const MyButton = (props: ButtonProps) => {
    return (
        <a className="myButton" href={props.link} target="_blank" rel="noreferrer">{props.text}</a>
    );
}

export default MyButton;