import {HStack, Text} from "@chakra-ui/react";
import React from "react";

interface CourseProps {
    courseName: string;
    from: number;
    to: number;
}

const CourseBox = (course: CourseProps) => {
    return (
        <HStack backgroundColor={"rgb(67, 67, 67)"} padding={"10px"}
                justifyContent="space-between" width={"100%"}>
            <Text fontFamily="Raleway-light" fontSize={{base: "18px", lg: "20px"}} color="white">{course.courseName}</Text>
            <Text fontFamily="Raleway-light" fontSize={{base: "16px", lg: "18px"}} color="white">От {course.from} до {course.to}</Text>
        </HStack>
    );
}

export default CourseBox;
